.slider_comp img{
  height: 100vh;
  object-fit: cover;
  width: 100%;
}

/* TODO: Add media queries for mobile and tablets */
@media screen and (min-width: 120px) and (max-width: 600px) {
  .slider_comp img {
      height: 50vh;
      object-fit: cover;
      width: 100%;
    }
}